import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    urnHome, urnSignIn, urnSignOut, urnMain, urnManifestationInfo, urnDrugIntoleranceInfo, urnFoodIntoleranceInfo
} from '../common/helper/UrlRouter';

import PAuthentication from '../pages/PAuthentication';
import PInterpreter from '../pages/PInterpreter';
import PManifestationInfo from '../pages/PManifestationInfo';
import PDrugIntoleranceInfo from '../pages/PDrugIntoleranceInfo';
import PFoodIntoleranceInfo from '../pages/PFoodIntoleranceInfo';


/**
 * Маршрутизация приложения 
 * @returns готовая страница
 */
export default function router() {
    return (
        <Routes>
            <Route path={urnHome} element={ PAuthentication(1) } />
            <Route path={urnSignIn} element={ PAuthentication(1) } />
            <Route path={urnSignOut} element={ PAuthentication(1) } />

            <Route path={urnMain} element={<PInterpreter />} />
            <Route path={`${urnManifestationInfo}:id`} element={<PManifestationInfo />} />
            <Route path={`${urnDrugIntoleranceInfo}:id`} element={<PDrugIntoleranceInfo />} />
            <Route path={`${urnFoodIntoleranceInfo}:id`} element={<PFoodIntoleranceInfo />} />
        </Routes>
    )
}