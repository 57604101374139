import React, { Component } from 'react';
import { Link, Typography } from '@mui/material';
import BuildCircle from '@mui/icons-material/BuildCircleOutlined';


/**
 * Компонент ссылка на версию продукта
 */
class VersionLink extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Link
                sx={{
                    display: 'flex',
                }}
                href={this.props.href}
                underline='none'
            >
                <BuildCircle 
                    sx={{
                        color: this.props.color,
                    }}
                    fontSize={this.props.size}
                />&ensp;
                <Typography
                    sx={{
                        fontSize: this.props.fontSize,
                        color: this.props.color,
                    }}
                >
                    {this.props.value}
                </Typography>
            </Link>
        )
    }
}

// &ensp;
// &emsp;


export {
    VersionLink,
}