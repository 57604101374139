const urlHttp = `http://`;
const urlHttps = `https://`;
const urlLocalhost = `localhost`;
const urlDev = `service.socmedica.dev`;
const urlProd = `service.socmedica.com`;

const localHttpService = `${urlHttp}${urlLocalhost}:7701`;
const devHttpService = `${urlHttp}${urlDev}:9060`;
const prodHttpService = `${urlHttp}${urlProd}:9060`;

const localHttpsService = `${urlHttps}${urlLocalhost}:7710`;
const devHttpsService = `${urlHttps}${urlDev}:9060`;
const prodHttpsService = `${urlHttps}${urlProd}:9060`;

const debug = false;

/**
 * Получить адрес BackEnd сервиса
 * @returns url адрес 
 */
export default function getUrnService() {
    return debug 
        ? localHttpService 
        : devHttpsService;
} 