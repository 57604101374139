import {
    urnHome
} from "../helper/UrlRouter";


/**
 * Бизнес логика выхода из системы
 */
async function handleLogoutClick() {
    window.location.href = `${urnHome}`;
}

export {
    handleLogoutClick
}