import React from 'react';
import { Formik } from 'formik';
import { Grid, Typography } from '@mui/material';
import { handleFormSubmit } from '../common/widgets/SignIn';

import { TextFld, PasswordFld } from '../components/Fields';
import { WelcomeImg } from '../components/Images';
import { SubmitBtn } from '../components/Buttons';
import { VersionLink } from '../components/Links';


const model = {
    welcomeImg: {
        src: '/img/logo512.png',
        width: '68px',
        alt: '',
    },
    header: {
        value: 'Руген',
        fontSize: '36px',
        color: '#5C5D9A',
    },
    subHeader: {
        value: 'Интерпретатор генетических данных пациента',
        fontSize: '25px',
        color: '#000000',
    },
    email: {
        size: 'small',
        type: 'text',
        variant: 'outlined',
        name: 'email',
        label: 'Логин',
    },
    pass: {
        size: 'small',
        type: 'password',
        variant: 'outlined',
        name: 'pass',
        label: 'Пароль',
    },
    submit: {
        variant: 'contained',
        value: 'вход',
    },
    version: {
        href: '',
        fontSize: '14px',
        size: 'small',
        color: '#8185B6',
        value: '1.0.0.6',
    },
}

let context = {
    email: "",
    pass: "",
}


/**
 * Виджет окна авторизации в системе
 * @param {Object} props параметры
 * @returns виджет
 */
export default function SignIn(props) {
    return (
        <Grid
            sx={{
                minHeight: '100%',
                direction: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            container
        >
            <Grid
                item xs={1} sm={3} md={3} lg={3} xl={4}
            />
            <Grid
                item xs={8} sm={6} md={6} lg={6} xl={4}
            >
                <Formik
                    onSubmit={handleFormSubmit} initialValues={context} validationSchema=''
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid
                                container
                            >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <WelcomeImg
                                        src={model.welcomeImg.src} width={model.welcomeImg.width} alt={model.welcomeImg.alt}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: model.header.fontSize,
                                            color: model.header.color,
                                        }}>
                                        {model.header.value}
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: model.subHeader.fontSize,
                                            color: model.subHeader.color,
                                        }}>
                                        {model.subHeader.value}
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        paddingTop: '30px',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <TextFld
                                        size={model.email.size} type={model.email.type} variant={model.email.variant}
                                        name={model.email.name} label={model.email.label} value={values.email}
                                        handleBlur={handleBlur} handleChange={handleChange}
                                        touched={touched.email} error={errors.email}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        paddingTop: '20px',
                                    }}
                                    item xs={12} sm={12} md={12} lg={12} xl={12}
                                >
                                    <PasswordFld
                                        size={model.pass.size} variant={model.pass.variant}
                                        name={model.pass.name} label={model.pass.label} value={values.pass}
                                        handleBlur={handleBlur} handleChange={handleChange}
                                        touched={touched.pass} error={errors.pass}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        paddingTop: '20px',
                                    }}
                                    item xs={6} sm={6} md={4} lg={2} xl={2}
                                >
                                    <SubmitBtn
                                        variant={model.submit.variant} value={model.submit.value}
                                    />
                                </Grid>                                
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',                                       
                                        paddingTop: '20px',
                                    }}
                                    item xs={6} sm={6} md={8} lg={10} xl={10}
                                >                                    
                                    <VersionLink 
                                        color={model.version.color} fontSize={model.version.fontSize} 
                                        size={model.version.size} value={model.version.value} href={model.version.href}
                                    />                             
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
            <Grid
                item xs={1} sm={3} md={3} lg={3} xl={4}
            />
        </Grid>
    )
}