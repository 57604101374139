import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    TextField, IconButton
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const thema = {
    color: '#8185B6 !important',
    backgroundColor: '#FFFF !important',
    borderBottomColor: '#8185B6 !important',
    borderColor: '#8185B6 !important',
    fontColor: '##919EAB !important',
}


/**
 * Компонент текстовое поле
 */
class TextFld extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <TextField
                sx={{
                    backgroundColor: thema.backgroundColor,
                    '& label.Mui-focused': {
                        color: thema.color,
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: thema.borderBottomColor,
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: thema.borderColor,
                        },
                        '&:hover fieldset': {
                            borderColor: thema.borderColor,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: thema.borderColor,
                        },
                    },
                    
                }}
                fullWidth id={uuidv4()} size={this.props.size} type={this.props.type} variant={this.props.variant}
                name={this.props.name} label={this.props.label} value={this.props.value}
                onBlur={this.props.handleBlur} onChange={this.props.handleChange}
                helperText={this.props.touched && this.props.error} error={Boolean(this.props.error && this.props.touched)}
            />
        )
    }
}

/**
 * Компонент поле ввода пароля
 */
class PasswordFld extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false,
        }

        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        });
    }

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    render() {
        return (
            <TextField
                sx={{
                    backgroundColor: thema.backgroundColor,
                    '& label.Mui-focused': {
                        color: thema.color,
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: thema.borderBottomColor,
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: thema.borderColor,
                        },
                        '&:hover fieldset': {
                            borderColor: thema.borderColor,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: thema.borderColor,
                        },
                    },
                }}
                fullWidth id={uuidv4()} size={this.props.size} type={this.state.showPassword ? 'text' : 'password'}
                variant={this.props.variant} name={this.props.name} label={this.props.label} value={this.props.value}
                onBlur={this.props.handleBlur} onChange={this.props.handleChange}
                helperText={this.props.touched && this.props.error} error={Boolean(this.props.error && this.props.touched)}
                InputProps={{
                    endAdornment:
                        <IconButton
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                        >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                }}
            />
        )
    }
}


export {
    TextFld, PasswordFld
}