import React, { Component } from 'react';
import {
    Button, IconButton
} from '@mui/material';


const thema = {
    color: '#8185B6 !important',
    backgroundColor: '#8185B6 !important',
    borderColor: '#8185B6 !important',
}


/**
 * Компонент кнопка подтверждения
 */
class SubmitBtn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Button
                sx={{
                    backgroundColor: thema.backgroundColor,
                    '&:hover': {
                        backgroundColor: thema.backgroundColor,
                    },
                    '&:active': {
                        boxShadow: 'none',
                        backgroundColor: thema.backgroundColor,
                        borderColor: thema.borderColor,
                    },
                }}
                fullWidth type='submit' variant={this.props.variant}
            >
                {this.props.value}
            </Button>
        )
    }
}

/**
 * Компонент кнопка с иконкой в начале
 */
class StartIconBtn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Button
                sx={{
                    my: 2,
                    backgroundColor: thema.backgroundColor,
                    '&:hover': {
                        backgroundColor: thema.backgroundColor,
                    },
                    '&:active': {
                        boxShadow: 'none',
                        backgroundColor: thema.backgroundColor,
                        borderColor: thema.borderColor,
                    },
                }}
                fullWidth variant="contained"
                startIcon={this.props.startIcon} onClick={this.props.handleClick}
            >
                {this.props.value}
            </Button>
        )
    }
}

/**
 * Компонент иконка в виде кнопки
 */
class IconBtn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <IconButton
                sx={{
                    color: thema.backgroundColor,
                }}
                onClick={this.props.handleClick}
            >
                {this.props.icon}
            </IconButton>
        )
    }
}

export {
    SubmitBtn, StartIconBtn, IconBtn
}
