import getUrnService from '../helper/UrnService';
import {
    urlRouteAll, urlRouteAllByFile,
} from '../helper/UrlApi';
import { responseCheck } from '../api/ResponseValidation';
import { alert204, alert500 } from '../helper/Alert';
import { alertWarn, alertError } from '../helper/ToastAlert';


const msg204 = 'Ошибка при конвертации файла, попробуйте снова!';
const msg500 = 'Непредвиденная ошибка сервиса!';


/**
 * Конструктор запроса API для комплексного анализ по всем маршрутам из файла
 * @param {String} authKey 
 * @param {Object} geneticLines 
 * @returns запрос
 */
function requestApiRouteAll(authKey, geneticLines) {
    return {
        authKey: authKey,
        geneticLines: geneticLines,
        routeParameters: 'all',
        mock: false,
    };
}

/**
 * API для комплексного анализ по всем маршрутам из файла
 * @param {object} req модель запроса
 */
async function apiRouteAllAsync(req) {
    let response = {
        alert: alert204(msg204),
        data: []
    };
    try {
        let res = await fetch(urlRouteAll(getUrnService()), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(req),
        });
        let result = await res.json();
        // Проверка статуса запроса
        if (!responseCheck(result)) {
            alertWarn(msg204);

            return response;
        }

        response = result;
    } catch (err) {
        alertError(msg500);
        response.alert = alert500(msg500);
        console.log(err);
    }

    return response;
}

/**
 * API для комплексного анализ по всем маршрутам из файла
 * @param {FormData} formData объект, представляющий данные HTML формы
 */
async function apiRouteAllByFileAsync(formData) {
    let response = {
        alert: alert204(msg204),
        data: []
    };
    try {
        let res = await fetch(urlRouteAllByFile(getUrnService()), {
            method: 'POST',
            body: formData
        });
        let result = await res.json();
        // Проверка статуса запроса
        if (!responseCheck(result)) {
            alertWarn(msg204);

            return response;
        }

        response = result;
    } catch (err) {
        alertError(msg500);
        response.alert = alert500(msg500);
        console.log(err);
    }

    return response;
}


export {
    apiRouteAllAsync, requestApiRouteAll, apiRouteAllByFileAsync
}