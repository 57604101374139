const uriApi = `/Api`;
const uriConverter = `/Converter`;
const uriInterpreter = `/Interpreter`;


/**
 * API для анализ файла *.g.vcf.filtered.annotated и конвертации в универсальный формат *.json
 * @param {String} urnServer Адрес к REST API сервиса
 * @returns {String} Urn api 
 */
function urlFileToAnalysis(urnServer) {
    const uri = `/FileToAnalysis`;

    return `${urnServer}${uriApi}${uriConverter}${uri}`;
}

/**
 * API для комплексного анализ по всем маршрутам из файла
 * @param {String} urnServer Адрес к REST API сервиса
 * @returns {String} Urn api 
 */
function urlRouteAllByFile(urnServer){
    const uri = `/RouteAllByFile`;

    return `${urnServer}${uriApi}${uriInterpreter}${uri}`;
}

/**
 * API для комплексного анализ по всем маршрутам
 * @param {String} urnServer Адрес к REST API сервиса
 * @returns {String} Urn api 
 */
function urlRouteAll(urnServer){
    const uri = `/RouteAll`;

    return `${urnServer}${uriApi}${uriInterpreter}${uri}`;
}


export {
    urlFileToAnalysis, urlRouteAll, urlRouteAllByFile,
}