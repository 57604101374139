import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/**
 * Компонент контейнер уведомлений
 */
class AlertToast extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ToastContainer
                style={{ 
                    width: "400px" 
                }}
                position='bottom-center' autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' transition={Flip} 
                draggablePercent={45}
            />
        )
    }
}


export {
    AlertToast
}