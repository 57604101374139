import React, { Component } from 'react';
import {
    Grid, Box, ListItem, ListItemText, ListItemAvatar, Divider, LinearProgress, Typography
} from '@mui/material';
import { IconBtn } from './Buttons';

import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';


const GetChanceColor = (props) => {
    if (props.chance <= 100 && props.chance >= 70) {
        return '#FF0000';
    }
    if (props.chance <= 69 && props.chance >= 30) {
        return '#FD9801';
    }
    if (props.chance <= 29 && props.chance >= 0) {
        return '#008000';
    }
}


/**
 * Компонент элемент списка проявлений
 */
class ManifestationItemLst extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <ListItem
                    sx={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                    }}
                >
                    <ListItemText
                        sx={{
                            '& .MuiListItemText-primary': {
                                fontSize: '18px',
                            }
                        }}
                        primary={this.props.name}
                        secondary={
                            <span
                                style={{
                                    width: '100%',
                                }}>
                                <LinearProgress
                                    sx={{
                                        padding: '2px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        color: GetChanceColor(this.props),
                                    }}
                                    color='inherit' variant='determinate' value={this.props.chance}
                                />
                                <span
                                    style={{
                                        fontSize: '14px',
                                    }}
                                >
                                    {`Уровень риска: ${this.props.chance}%`}
                                </span>
                            </span>
                        }
                    />
                    <ListItemAvatar
                        sx={{
                            paddingLeft: '25px',
                        }}
                    >
                        <IconBtn
                            icon={this.props.icon}
                            handleClick={() => this.props.handleClick(this.props.id)}
                        />
                    </ListItemAvatar>
                </ListItem>
                <Divider />
            </>
        )
    }
}

/**
 * Компонент элемент списка фармакотерапия
 */
class DrugIntoleranceItemLst extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <ListItem
                    sx={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                    }}
                >
                    <ListItemText
                        sx={{
                            '& .MuiListItemText-primary': {
                                fontSize: '18px',
                            }
                        }}
                        primary={`${this.props.name}${String.fromCodePoint(0x02122)}`}
                        secondary={
                            <span
                                sx={{
                                    width: '100%',
                                }}>
                                <LinearProgress
                                    sx={{
                                        padding: '2px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        color: GetChanceColor(this.props),
                                    }}
                                    color='inherit' variant='determinate' value={this.props.chance}
                                />
                                <span
                                    style={{
                                        fontSize: '14px',
                                    }}
                                >
                                    {`Побочные реакции, уровень риска: ${this.props.chance}%`}
                                </span>
                            </span>
                        }
                    />
                    <ListItemAvatar
                        sx={{
                            paddingLeft: '25px',
                        }}
                    >
                        <IconBtn
                            icon={this.props.icon}
                            handleClick={() => this.props.handleClick(this.props.id)}
                        />
                    </ListItemAvatar>
                </ListItem>
                <Divider />
            </>
        )
    }
}

/**
 * Компонент элемент списка питания
 */
class FoodIntoleranceItemLst extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <ListItem
                    sx={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                    }}
                >
                    <ListItemText
                        sx={{
                            '& .MuiListItemText-primary': {
                                fontSize: '18px',
                            }
                        }}
                        primary={this.props.name}
                        secondary={
                            <span
                                style={{
                                    width: '100%',
                                    paddingBottom: '5px',
                                }}>
                                <LinearProgress
                                    sx={{
                                        padding: '2px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        color: GetChanceColor(this.props),
                                    }}
                                    color='inherit' variant='determinate' value={this.props.chance}
                                />
                                <span
                                    style={{
                                        fontSize: '14px',
                                    }}
                                >
                                    {`Уровень риска: ${this.props.chance}%`}
                                </span>
                            </span>
                        }
                    />
                    <ListItemAvatar
                        sx={{
                            paddingLeft: '25px',
                        }}
                    >
                        <IconBtn
                            icon={this.props.icon}
                            handleClick={() => this.props.handleClick(this.props.id)}
                        />
                    </ListItemAvatar>
                </ListItem>
                <Divider />
            </>
        )
    }
}

/**
 * Компонент элемент списка информация о гене
 */
class GenInfoItemLst extends Component {
    constructor(props) {
        super(props);

        this.state = {
            box: {
                box: {
                    display: 'flex',
                    travelExploreOutlinedIcon: {
                        color: '#FB0101',
                        height: '24px',
                        paddingRight: '5px',
                    },
                    typography: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                    },
                },
                grid: {
                    paddingTop: '10px',
                    header: {
                        typography: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                        },
                        column1: 'Полиморфизм',
                        column2: 'Локация',
                        column3: 'Аллели',
                        column4: 'Белок',
                    },
                },
            },
        }

        this.primaryItem = this.primaryItem.bind(this);
        this.secondaryItem = this.secondaryItem.bind(this);
    }

    primaryItem = (elem) => {
        let value = elem.descryption;
        if (value === '') {
            value = elem.name;
        }

        return (
            <Box>
                <Box
                    sx={{
                        display: this.state.box.box.display,
                    }}
                >
                    <TravelExploreOutlinedIcon
                        sx={{
                            color: this.state.box.box.travelExploreOutlinedIcon.color,
                            height: this.state.box.box.travelExploreOutlinedIcon.height,
                            paddingRight: this.state.box.box.travelExploreOutlinedIcon.paddingRight,
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: this.state.box.box.typography.fontSize,
                            fontWeight: this.state.box.box.typography.fontWeight,
                        }}
                    >
                        {value}
                    </Typography>
                </ Box>
                <Grid
                    sx={{
                        paddingTop: this.state.box.grid.paddingTop
                    }}
                    container
                >
                    <Grid
                        item xs={2} sm={2} md={2} lg={2} xl={2}
                    >
                        <Typography
                            sx={{
                                fontSize: this.state.box.grid.header.typography.fontSize,
                                fontWeight: this.state.box.grid.header.typography.fontWeight,
                            }}
                        >
                            {this.state.box.grid.header.column1}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={2} sm={2} md={2} lg={2} xl={2}
                    >
                        <Typography
                            sx={{
                                fontSize: this.state.box.grid.header.typography.fontSize,
                                fontWeight: this.state.box.grid.header.typography.fontWeight,
                            }}
                        >
                            {this.state.box.grid.header.column2}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={1} sm={1} md={1} lg={1} xl={1}
                    >
                        <Typography
                            sx={{
                                fontSize: this.state.box.grid.header.typography.fontSize,
                                fontWeight: this.state.box.grid.header.typography.fontWeight,
                            }}
                        >
                            {this.state.box.grid.header.column3}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={1} sm={1} md={1} lg={1} xl={1}
                    >
                        <Typography
                            sx={{
                                fontSize: this.state.box.grid.header.typography.fontSize,
                                fontWeight: this.state.box.grid.header.typography.fontWeight,
                            }}
                        >
                            {this.state.box.grid.header.column4}
                        </Typography>
                    </Grid>
                    <Grid
                        item xs={6} sm={6} md={6} lg={6} xl={6}
                    />
                </Grid>
            </Box>
        )
    }

    secondaryItem = (value) => {
        return (
            <>
                {value.map((elem) => (
                    <MutationInfoItem data={elem} />
                ))}
            </>
        )
    }

    render() {
        return (
            <>
                <ListItem
                    sx={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                    }}
                >
                    <ListItemText
                        sx={{
                            '& .MuiListItemText-primary': {
                                fontSize: '16px',
                            }
                        }}
                        primary={this.primaryItem(this.props.elem)}
                        secondary={this.secondaryItem(this.props.elem.mutations)}
                    />
                </ListItem>
                <Divider />
            </>
        )
    }
}

/**
 * Компонент элемент списка информация о мутации и аллели
 */
class MutationInfoItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            grid: {
                value: {
                    typography: {
                        fontSize: '14px',
                    },
                    column1: this.props.data.rs,
                    column2: '-',
                    column4: '-',
                },
            },
        }

        this.allels = this.allels.bind(this)
    }

    allels = (value) => {
        let result = '-';

        value.map((elem) => {
            if (result === '-') {
                result = elem.code;
            } else {
                result = `${result}, ${elem.code}`;
            }
        });

        return result;
    }

    render() {
        return (
            <Grid
                sx={{
                    paddingTop: this.state.grid.paddingTop
                }}
                container
            >
                <Grid
                    item xs={2} sm={2} md={2} lg={2} xl={2}
                >
                    <Typography
                        sx={{
                            fontSize: this.state.grid.value.typography.fontSize,
                        }}
                    >
                        {this.state.grid.value.column1}
                    </Typography>
                </Grid>
                <Grid
                    item xs={2} sm={2} md={2} lg={2} xl={2}
                >
                    <Typography
                        sx={{
                            fontSize: this.state.grid.value.typography.fontSize,
                        }}
                    >
                        {this.state.grid.value.column2}
                    </Typography>
                </Grid>
                <Grid
                    item xs={1} sm={1} md={1} lg={1} xl={1}
                >
                    <Typography
                        sx={{
                            fontSize: this.state.grid.value.typography.fontSize,
                        }}
                    >
                        {this.allels(this.props.data.allels)}
                    </Typography>
                </Grid>
                <Grid
                    item xs={1} sm={1} md={1} lg={1} xl={1}
                >
                    <Typography
                        sx={{
                            fontSize: this.state.grid.value.typography.fontSize,
                        }}
                    >
                        {this.state.grid.value.column4}
                    </Typography>
                </Grid>
                <Grid
                    item xs={6} sm={6} md={6} lg={6} xl={6}
                />
            </Grid>
        )
    }
}

export {
    ManifestationItemLst, DrugIntoleranceItemLst, FoodIntoleranceItemLst, GenInfoItemLst, MutationInfoItem
}