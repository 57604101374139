import React, { Component } from 'react';
import {
    Box, Grid, Card, CardContent, Typography, IconButton, Button, CircularProgress, styled
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import UploadFileIcon from '@mui/icons-material/UploadFile';


const thema = {
    color: '#545454',
    border: '#8185B6',
    backgroundColor: '#8185B61A',
    iconButtonColor: '#FF0000',
    buttonColor: '#8185B6',
};


/**
 * Компонент карточка загрузки файла
 */
class UploadFileCard extends Component {
    constructor(props) {
        super(props);

        let size = (this.props.size / 1024 / 1024).toFixed(2);
        let split = `${String.fromCharCode(8195)}${String.fromCharCode(8195)}`;
        this.state = {
            line2: `${this.props.name}${split}${size}Мб`,
        }
    }

    render() {
        return (
            <Card
                sx={{
                    display: 'flex',
                    border: `1px solid ${thema.border}`,
                    backgroundColor: thema.backgroundColor,
                    minHeight: '200px',
                    marginBottom: '25px',
                    alignItems: 'center',
                }}>
                <CardContent
                    sx={{
                        padding: '50px !important'
                    }}
                >
                    <Typography
                        sx={{
                            color: thema.color,
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                        variant='body2'
                    >
                        {this.props.line1}
                    </Typography>
                    <Typography
                        sx={{
                            color: thema.color,
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}
                        variant='body2'
                    >
                        {this.state.line2}
                        <IconButton onClick={this.props.handleClick}>
                            <DisabledByDefaultIcon
                                sx={{
                                    color: thema.iconButtonColor,
                                }} />
                        </IconButton>
                    </Typography>
                </CardContent>
            </Card>
        )
    }
}

/**
 * Компонент карточка выбора загружаемого файла
 */
class FileCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const VisuallyHiddenInput = styled('input')({
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: 1,
            overflow: 'hidden',
            position: 'absolute',
            bottom: 0,
            left: 0,
            whiteSpace: 'nowrap',
            width: 1,
        });

        return (
            <Card
                sx={{
                    border: `1px solid ${thema.border}`,
                    backgroundColor: thema.backgroundColor,
                    minHeight: '200px',
                    marginBottom: '25px'
                }}>
                <CardContent
                    sx={{
                        padding: '50px !important'
                    }}>
                    <Typography
                        sx={{
                            color: thema.color,
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                        variant='body2'
                    >
                        {this.props.line1}
                    </Typography>
                    <Typography
                        sx={{
                            color: thema.color,
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                        variant='body2'
                    >
                        {this.props.line2}
                    </Typography>
                    <Typography
                        sx={{
                            color: thema.color,
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                        variant='body2'
                    >
                        {this.props.line3}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px'
                        }}>
                        <Button
                            sx={{
                                backgroundColor: thema.buttonColor,
                                '&:hover': {
                                    backgroundColor: thema.buttonColor,
                                },
                                '&:active': {
                                    boxShadow: 'none',
                                    backgroundColor: thema.buttonColor,
                                    borderColor: thema.buttonColor,
                                },
                            }}
                            component='label' variant='contained' startIcon={<UploadFileIcon />}>
                            Выбрать файл ...
                            <VisuallyHiddenInput
                                type="file" accept=".gvcf,.csv,.vcf," onChange={this.props.handleChange} />
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        )
    }
}

/**
 * Компонент карточка информации о загруженном файле
 */
class ProgressCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Grid container>
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px',
                    }}
                    item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CircularProgress
                        color="secondary" />
                </Grid>
                <Grid
                    item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                        sx={{
                            color: thema.color,
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        {this.props.value}
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

/**
 * Компонент карточка информации об ошибке обработки
 */
class ProgressErrorCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card
                sx={{
                    border: `1px solid ${thema.border}`,
                    backgroundColor: thema.backgroundColor,
                    minHeight: '50px',
                    marginBottom: '25px'
                }}>
                <CardContent
                    sx={{
                        padding: '50px !important'
                    }}>
                    <Typography
                        sx={{
                            color: thema.color,
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                        variant='body2'
                    >
                        {this.props.value}
                    </Typography>
                </ CardContent>
            </ Card>
        )
    }
}


export {
    FileCard, UploadFileCard, ProgressCard, ProgressErrorCard
}