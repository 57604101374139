import GetUrnService from '../helper/UrnService';
import { urlFileToAnalysis } from '../helper/UrlApi';
import {
    responseCheck,
} from '../api/ResponseValidation';
import { alert204, alert500 } from '../helper/Alert';
import { alertWarn, alertError } from "../helper/ToastAlert";


const msg204 = 'Ошибка конвертации файла, попробуйте снова!';
const msg500 = 'Непредвиденная ошибка сервиса!';


/**
 * API для анализ файла *.g.vcf.filtered.annotated и конвертации в универсальный формат *.json
 * @param {FormData} formData объект, представляющий данные HTML формы
 */
async function apiFileToAnalysisAsync(formData) {
    let response = {
        alert: alert204(msg204),
        data: []
    };
    try {
        let res = await fetch(urlFileToAnalysis(GetUrnService()), {
            method: 'POST',
            body: formData
        });
        let result = await res.json();
        // Проверка статуса запроса
        if (!responseCheck(result)) {
            alertWarn(msg204);

            return response;
        }

        response = result;
    } catch (err) {
        alertError(msg500);
        response.alert = alert500(msg500);
        console.log(err);
    }

    return response;
}


export {
    apiFileToAnalysisAsync
}