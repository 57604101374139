import React from 'react';
import { useParams } from 'react-router-dom';

import AppBarMenu from '../widgets/AppBar';
import FoodIntoleranceInfo from '../widgets/FoodIntoleranceInfo';


const model = {
    appBarMenu: {
        headerValue: 'Питание',
    },
};


/**
 * Страница информация о продуктах питания
 * @returns страница
 */
export default function PFoodIntoleranceInfo() {
    const params = useParams();

    return (
        <>
            <AppBarMenu headerValue={model.appBarMenu.headerValue} />
            <FoodIntoleranceInfo id={params.id}/>
        </>
    )
}