import React from 'react';
import SignIn from '../widgets/SignIn';
import { AlertToast } from '../components/Alerts';


/**
 * Страница аутентификации пользователя в системе
 * @param {Number} param параметр отображаемого виджета: 
 * 1 - авторизация, 
 * 2 - востановление пароля, 
 * 3 - регистрация, 
 * 4 - подтверждение почты
 * @returns страница
 */
export default function PAuthentication(param) {
    return (
        <>
            {(() => {
                switch (param) {
                    case 1:
                        return <SignIn />
                    default:
                        return <></>
                }
            })()}
            <AlertToast />
        </>
    )
}
