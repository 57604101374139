import React from 'react';
import { useParams } from 'react-router-dom';

import AppBarMenu from '../widgets/AppBar';
import DrugIntoleranceInfo from '../widgets/DrugIntoleranceInfo';


const model = {
    appBarMenu: {
        headerValue: 'Фармакология',
    },
};


/**
 * Страница информация о фармакотерапии
 * @returns страница
 */
export default function PDrugIntoleranceInfo() {
    const params = useParams();

    return (
        <>
            <AppBarMenu headerValue={model.appBarMenu.headerValue} />
            <DrugIntoleranceInfo id={params.id}/>
        </>
    )
}
