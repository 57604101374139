import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGenomeSciencePanel } from '../shared/reducers/GenomeSciencePanelSlice';
import { setFileInfo } from '../shared/reducers/FileInfoSlice';
import { fillFormData } from '../common/widgets/PanelPreload';
import {
    Grid, Accordion, AccordionDetails
} from '@mui/material';

import { ImgSummaryAcn } from '../components/Accordions';
import { FileCard, UploadFileCard } from '../components/Cards';


const model = {
    id: "1a",
    svg: <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 2H10L8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2ZM11 9V12C11 12.55 10.55 13 10 13C9.45 13 9 12.55 9 12V9H7.21C6.76 9 6.54 8.46 6.86 8.15L9.66 5.36C9.86 5.16 10.17 5.17 10.37 5.36L13.16 8.15C13.46 8.46 13.24 9 12.8 9H11Z" fill="#8185B6" /></svg>,
    value: "Загрузить анализ",
    uploadFileCard:{
        line1: "Вами был загружен следующий файл",
    },
    fileCard:{
        line1: "Выберите файлы для загрузки",
        line2: "Форматы: *.gvcf, *.csv, *.vcf",
        line3: "Размер не более 4 Гб",
    },
}


/**
 * Виджет панели загрузки файлов для анализа
 * @returns виджет
 */
export default function PanelPreload(props) {
    const dispatch = useDispatch();
    const genomeSciencePanel = useSelector((state) => state.genomeSciencePanel.value);
    const fileInfo = useSelector((state) => state.fileInfo.data);


    const handleChange = (panel) => (event, newExpanded) => {
        dispatch(setGenomeSciencePanel(newExpanded ? panel : false));
    };

    const handleUploadChange = (event) => {
        let file = event.target.files[0];
        let formData = fillFormData(file);

        dispatch(setFileInfo({
            name: file.name,
            size: file.size,
            formData: formData,
            fl: true,
        }));
        dispatch(setGenomeSciencePanel('panel2'));
    };

    const FileCards = () => {
        if (fileInfo.fl) {
            return (
                <UploadFileCard 
                    line1={model.uploadFileCard.line1} name={fileInfo.name} size={fileInfo.size} 
                    handleClick={props.handleClearChange}
                />
            )
        } else {
            return (
                <FileCard
                    line1={model.fileCard.line1} line2={model.fileCard.line2} line3={model.fileCard.line3} 
                    handleChange={handleUploadChange} 
                />
            );
        }
    }

    return (
        <Accordion expanded={genomeSciencePanel === 'panel1'} onChange={handleChange('panel1')}>
            <ImgSummaryAcn id={model.id} svg={model.svg} value={model.value} />
            <AccordionDetails>
                <Grid
                    container>
                    <Grid
                        item xs={1} sm={1} md={2} lg={2} xl={2} />
                    <Grid
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <FileCards />
                    </Grid>
                    <Grid
                        item xs={1} sm={1} md={2} lg={2} xl={2} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}
