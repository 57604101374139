import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGenomeStudiesPanel } from '../shared/reducers/GenomeStudiesPanelSlice';
import {
    Accordion, AccordionDetails
} from '@mui/material';
import { getAllDrugIntolerance } from '../common/helper/ResponseApiHandler';

import { ImgSummaryAcn } from '../components/Accordions';
import DrugIntoleranceList from './DrugIntoleranceList';


const thema = {
    border: '#8185B6',
};

const model = {
    id: "2s",
    svg: <svg fill="#8185B6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M9.426,6.3,7.57,8.156l2.137,2.137a1,1,0,0,1-1.414,1.414L6.156,9.57,4.3,11.425a4.437,4.437,0,0,0,4.413,7.388,1,1,0,0,1,.574,1.916,6.437,6.437,0,0,1-6.4-10.718L8.012,4.885a6.442,6.442,0,0,1,9.8.829,1,1,0,1,1-1.63,1.159A4.44,4.44,0,0,0,9.426,6.3ZM23,15a6,6,0,1,1-6-6A6.008,6.008,0,0,1,23,15Zm-6-4a3.947,3.947,0,0,0-2.019.567l5.452,5.452A3.957,3.957,0,0,0,21,15,4,4,0,0,0,17,11Zm0,8a3.947,3.947,0,0,0,2.019-.567l-5.452-5.452A3.957,3.957,0,0,0,13,15,4,4,0,0,0,17,19Z"></path></g></svg>,
    value: "Фармакология",
};


/**
 * Виджет панели иследований группа Фармакотерапия
 * @returns виджет
 */
export default function PanelStudiesDrugIntolerance(props) {
    const dispatch = useDispatch();
    const genomeStudiesPanel = useSelector((state) => state.genomeStudiesPanel.value);
    const geneticMap = useSelector((state) => state.geneticMap.data);
    const drugIntolerances = getAllDrugIntolerance(geneticMap);
    

    const handleChange = (panel) => (event, newExpanded) => {
        dispatch(setGenomeStudiesPanel(newExpanded ? panel : false));
    };


    return (
        <Accordion
            sx={{
                border: `1px solid ${thema.border}`,
            }}
            expanded={genomeStudiesPanel === 'subPanel2'} onChange={handleChange('subPanel2')}>
            <ImgSummaryAcn
                id={model.id} svg={model.svg} value={model.value} 
            />
            <AccordionDetails>
                <DrugIntoleranceList 
                    elems={drugIntolerances}
                />
            </AccordionDetails>
        </Accordion>
    )
}
