import React, { Component } from 'react';
import {
    AccordionSummary, Typography, SvgIcon, Box, Divider, LinearProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const GetChanceColor = (props) => {
    if (props.chance <= 100 && props.chance >= 70) {
        return '#FF0000';
    }
    if (props.chance <= 69 && props.chance >= 30) {
        return '#FD9801';
    }
    if (props.chance <= 29 && props.chance >= 0) {
        return '#008000';
    }
}


/**
 * Компонент заголовок аккордеона
 */
class SummaryAcn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: `panel${this.props.id}-header`,
            ariaControls: `panel${this.props.id}-content`
        }
    }

    render() {
        return (
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />} aria-controls={this.state.ariaControls} id={this.state.id}>
                <Typography
                    fontSize={this.props.fontSize}>
                    {this.props.value}
                </Typography>
            </AccordionSummary>
        )
    }
}

/**
 * Компонент заголовок аккордеона с иконкой
 */
class ImgSummaryAcn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: `panel${this.props.id}-header`,
            ariaControls: `panel${this.props.id}-content`
        }
    }

    render() {
        return (
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />} aria-controls={this.state.ariaControls} id={this.state.id}>
                <SvgIcon
                    width='20px' height='16px'
                    sx={{
                        marginRight: "10px"
                    }}>
                    {this.props.svg}
                </SvgIcon>
                <Typography
                    fontSize='18px'>
                    {this.props.value}
                </Typography>
            </AccordionSummary>
        )
    }
}

/**
 * Компонент заголовок аккордеона информация о проявлении
 */
class SummaryInfoAcn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: `panel${this.props.model.id}-header`,
            ariaControls: `panel${this.props.model.id}-content`,
            name: props.tm ? `${this.props.data.name}${String.fromCodePoint(0x02122)}` : this.props.data.name,
        }
    }

    render() {
        return (
            <AccordionSummary
                sx={{
                    '& .MuiAccordionSummary-content' : {
                        display: this.props.model.display,
                        flexDirection: this.props.model.flexDirection,
                    },
                    padding: this.props.model.padding,
                }}
                expandIcon={<ExpandMoreIcon />} aria-controls={this.state.ariaControls} id={this.state.id}>
                <Typography
                    sx={{
                        textAlign: this.props.model.typography.textAlign,
                        fontWeight: this.props.model.typography.fontWeight,
                        fontSize: this.props.model.typography.fontSize,
                        color: this.props.model.typography.color,
                    }}>
                    {this.state.name}
                </Typography>
                <LinearProgress
                    sx={{
                        padding: this.props.model.linearProgress.padding,
                        marginTop: this.props.model.linearProgress.marginTop,
                        marginBottom: this.props.model.linearProgress.marginBottom,
                        marginRight: this.props.model.linearProgress.marginRight,
                        color: GetChanceColor(this.props.data),
                    }}
                    color='inherit' variant='determinate' value={this.props.chance}
                />
                <Box
                    sx={{
                        display: this.props.model.box.display,
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: this.props.model.box.typography.textAlign,
                            paddingRight: this.props.model.box.typography.paddingRight,
                            fontSize: this.props.model.box.typography.fontSize,
                            color: this.props.model.box.typography.subColor,
                        }}>
                        {this.props.model.box.typography.value}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: this.props.model.box.typography.textAlign,
                            fontSize: this.props.model.box.typography.fontSize,
                            color: this.props.model.box.typography.subColor,
                        }}>
                        {this.props.data.chance}%
                    </Typography>
                </Box>
            </AccordionSummary>
        )
    }
}


export {
    SummaryAcn, ImgSummaryAcn, SummaryInfoAcn
}