import { getFileFormat } from './PInterpreter'

/**
 * Заполнить данные о файле перед отправкой
 * @param { Object } file файл
 */
function fillFormData(file) {
    let formData = new FormData();
    let format = getFileFormat(file);
    if (format === 'gvcf') {
        formData.append('Gvcf', file);
        formData.append('AuthKey', '1');
    } else if (format === 'vcf' || format === 'csv') {
        formData.append('File', file);
        formData.append('RouteParameters', 'all');
    }

    return formData;
}


export {
    fillFormData
}