/**
 * Валидация ответов от BeckEnd
 * @param {Object} res 
 * @returns true если запрос вернулся успешным, инаяе false
 */
function responseCheck(res) {
    // Проверка статуса запроса
    if (res.alert === undefined && 
        res.status !== undefined && 
        res.status !== 200) {        
        
        return false;
    }

    // Валидация
    if (res?.alert?.code !== '200') {

        return false;
    }

    return true;
}


export {
    responseCheck,
}