import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    data: {
        name: "",
        size: 0,
        fl: false,
    },
};


export const fileInfoSlice = createSlice({
    name: 'fileInfo',
    initialState,
    reducers: {
        setFileInfo: (state, action) => {
            state.data = action.payload;
        },
        resetFileInfo: (state) => {
            state.data = {
                name: "",
                size: 0,
                fl: false,
            };
        }
    },
})


export const { setFileInfo, resetFileInfo } = fileInfoSlice.actions;
export default fileInfoSlice.reducer;