import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: 'panel1',
};

export const genomeSciencePanel = createSlice({
    name: 'genomeSciencePanel',
    initialState,
    reducers: {
        setGenomeSciencePanel: (state, action) => {
            state.value = action.payload;
        }
    }
});


export const { setGenomeSciencePanel } = genomeSciencePanel.actions;
export default genomeSciencePanel.reducer;