import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
    Grid, Paper, Typography, Accordion, AccordionDetails, List
} from '@mui/material';
import { urnMain } from '../common/helper/UrlRouter';
import { getRouteByManifestation } from '../common/helper/ResponseApiHandler';

import { IconBtn } from '../components/Buttons';
import { SummaryInfoAcn } from '../components/Accordions';
import { GenInfoItemLst } from '../components/Lists';

import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';


const model = {
    grid: {
        paddingTop: '5px'
    },
    paper: {
        padding: '15px',
    },
    accordion: {
        id: '1a',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#545454',
        border: '#8185B6',
        boxShadow: 'none',
        summary: {
            padding: '0px',
            display: 'flex',
            flexDirection: 'column',
            typography: {
                fontSize: '21px',
                fontWeight: 'bold',
                textAlign: 'left',
                color: '#000000',
            },
            linearProgress:{
                padding: '2px',
                marginTop: '5px',
                marginBottom: '5px',
                marginRight: '25px',
            },
            box: {
                display: 'inline-flex',
                typography: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    subColor: '#545454',
                    color: '#000000',
                    value: 'Уровень риска',
                    paddingRight: '5px',
                },
            },
        },
    },
    genHeader: {
        fontSize: '21px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000000',
        value: 'Генетические факторы риска:',
        paddingTop: '15px',
    }
}


/**
 * Виджет информация о проявлении
 * @param { Object } props параметры
 * @returns виджет
 */
export default function ManifestationInfo(props) {
    const navigate = useNavigate();
    const geneticMap = useSelector((state) => state.geneticMap.data);
    const data = getRouteByManifestation(geneticMap, props.id);

    const handleClick = () => {
        navigate(urnMain);
    }

    return (
        <Grid
            sx={{
                paddingTop: model.grid.paddingTop,
            }}
            container
        >
            <Grid
                item xs={12} sm={12} md={12} lg={12} xl={12}
            >
                <Paper
                    sx={{
                        padding: model.paper.padding,
                    }}
                >
                    <IconBtn
                        icon={<UndoOutlinedIcon />}
                        handleClick={handleClick}
                    />
                    <Accordion
                        sx={{                            
                            boxShadow: model.accordion.boxShadow,
                        }}
                    >
                        <SummaryInfoAcn                            
                            model={model.accordion.summary}
                            data={data}
                        />
                        <AccordionDetails>
                            ...
                        </AccordionDetails>
                    </Accordion>
                    <Typography
                        sx={{
                            textAlign: model.genHeader.textAlign,
                            fontSize: model.genHeader.fontSize,
                            fontWeight: model.genHeader.fontWeight,
                            color: model.genHeader.color,
                            paddingTop: model.genHeader.paddingTop,
                        }}>
                        {model.genHeader.value}
                    </Typography>
                    <List
                        sx={{
                            width: '100%',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                        }}>
                        {data.gens.map((elem) => (
                            <GenInfoItemLst
                                key={uuidv4()} elem={elem}
                            />
                        ))}
                    </List>
                </Paper>
            </ Grid>
        </Grid>
    )
}
