/**
 * Получить тип файла
 * @param {Object} file 
 * @returns тип файла
 */
function getFileFormat(file) {
    let format = file.name.split('.').reverse();

    return format[0];
};


export {
    getFileFormat
}