import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGenomeSciencePanel } from '../shared/reducers/GenomeSciencePanelSlice';
import {
    Accordion, AccordionDetails
} from '@mui/material';

import { ImgSummaryAcn } from '../components/Accordions';
import PanelStudiesManifestation from './PanelStudiesManifestation';
import PanelStudiesDrugIntolerance from './PanelStudiesDrugIntolerance';
import PanelStudiesFoodIntolerance from './PanelStudiesFoodIntolerance';


const model = {
    id: "3a",
    svg: <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.69 15.37L20.83 14.37L19.83 12.64L18.38 13.13C18.06 12.86 17.7 12.65 17.3 12.5L17 11H15L14.7 12.49C14.3 12.64 13.94 12.85 13.62 13.12L12.17 12.63L11.17 14.36L12.31 15.36C12.23 15.86 12.23 16.12 12.31 16.62L11.17 17.62L12.17 19.35L13.62 18.86C13.94 19.13 14.3 19.34 14.7 19.49L15 21H17L17.3 19.51C17.7 19.36 18.06 19.15 18.38 18.88L19.83 19.37L20.83 17.64L19.69 16.64C19.77 16.13 19.77 15.87 19.69 15.37ZM16 18C14.9 18 14 17.1 14 16C14 14.9 14.9 14 16 14C17.1 14 18 14.9 18 16C18 17.1 17.1 18 16 18ZM8 4V9.41L10.36 11.77L11.4 9.98L10 8.59V4H8ZM18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.17 0 3.65 1.32 2 3.36V1H0V7H6V5H3.26C3.90497 4.07519 4.76351 3.31961 5.76276 2.79735C6.76202 2.27509 7.8725 2.00157 9 2C12.86 2 16 5.14 16 9H18ZM7.86 15.91C4.87 15.42 2.51 13.01 2.08 10H0.0599999C0.56 14.5 4.37 18 9 18H9.07L7.86 15.91Z" fill="#8185B6" /></svg>,
    value: "Результат исследования",
};


/**
 * Виджет панели результатов исследования генома
 * @returns виджет
 */
export default function PanelStudies() {
    const dispatch = useDispatch();
    const genomeSciencePanel = useSelector((state) => state.genomeSciencePanel.value);  


    const handleChange = (panel) => (event, newExpanded) => {
        dispatch(setGenomeSciencePanel(newExpanded ? panel : false));
    };


    return (
        <Accordion            
            expanded={genomeSciencePanel === 'panel3'} onChange={handleChange('panel3')}
        >
            <ImgSummaryAcn id={model.id} svg={model.svg} value={model.value} />
            <AccordionDetails>
                <PanelStudiesManifestation />
                <PanelStudiesDrugIntolerance />
                <PanelStudiesFoodIntolerance />
            </AccordionDetails>
        </Accordion>
    )
}
