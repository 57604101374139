import { configureStore } from '@reduxjs/toolkit';

import geneticMapSlice from '../shared/reducers/GeneticMapSlice';
import fileInfoSlice from '../shared/reducers/FileInfoSlice';
import genomeSciencePanelSlice from '../shared/reducers/GenomeSciencePanelSlice';
import genomeStudiesPanelSlice from '../shared/reducers/GenomeStudiesPanelSlice';


export const store = configureStore({
    reducer: {
        geneticMap: geneticMapSlice,
        fileInfo: fileInfoSlice,
        genomeSciencePanel: genomeSciencePanelSlice,
        genomeStudiesPanel: genomeStudiesPanelSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});