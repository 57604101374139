import React from 'react';
import { 
    handleLogoutClick 
} from '../common/widgets/AppBar';
import {
    Box, AppBar, Toolbar, IconButton, Typography
} from '@mui/material';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';


/**
 * Виджет меню
 * @param {Object} props параметры
 * @returns виджет
 */
export default function AppBarMenu(props) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position='static'>
                <Toolbar
                    sx={{
                        background: '#9DA2D3',
                        minHeight: '48px !important',
                    }}>
                    <img
                        style={{
                            marginRight: '1em'
                        }}
                        src='/img/logo128.png' alt={props.headerValue} width='38px'
                    />
                    <Typography
                        sx={{
                            alignItems: "center",
                            display: "flex",
                        }}
                        variant='h6' component='div'
                    >
                        {props.headerValue}
                    </Typography>
                    <Box
                        sx={{
                            marginLeft: 'auto',
                        }}>
                        <IconButton
                            sx={{
                                color: 'white',
                            }}
                            aria-label='Logout' onClick={handleLogoutClick}>
                            <LogoutOutlined />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}