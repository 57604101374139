import { toast } from 'react-toastify';


/**
 * 
 * @param {String} msg Текст уведомления
 */
function alertSuccess(msg) {
    toast.success(msg);
}

/**
 * 
 * @param {String} msg Текст уведомления
 */
function alertInfo(msg) {
    toast.info(msg);
}

/**
 * 
 * @param {String} msg Текст уведомления
 */
function alertWarn(msg) {
    toast.warn(msg);
}

/**
 * 
 * @param {String} msg Текст уведомления
 */
function alertError(msg) {
    toast.error(msg);
}

export {
    alertSuccess, alertInfo, alertWarn, alertError
}