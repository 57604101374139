import React from 'react';
import { useParams } from 'react-router-dom';

import AppBarMenu from '../widgets/AppBar';
import ManifestationInfo from '../widgets/ManifestationInfo';


const model = {
    appBarMenu: {
        headerValue: 'Риски заболеваний',
    },
};


/**
 * Страница информация о проявлении
 * @returns страница
 */
export default function PManifestationInfo() {
    const params = useParams();

    return (
        <>
            <AppBarMenu headerValue={model.appBarMenu.headerValue} />
            <ManifestationInfo id={params.id}/>
        </>
    )
}
