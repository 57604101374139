const weight = 0.17;
const fieldNames = {
    id: 'id',
    level: 'level',
    code: 'code',
    rs: 'rs',
    name: 'name',
    chance: 'chance',
    descryption: 'descryption',
    valueA: 'valueA',
    valueB: 'valueB',
    valueC: 'valueC',
    valueD: 'valueD',
    genList: 'genList',
    allelList: 'allelList',
    mutationList: 'mutationList',
    manifestationList: 'manifestationList',
    drugIntoleranceList: 'drugIntoleranceList',
    foodIntoleranceList: 'foodIntoleranceList',
    linkGenList: 'linkGenList',
    linkMutationList: 'linkMutationList',
    linkAllelList: 'linkAllelList',
    linkManifestationList: 'linkManifestationList',
    linkDrugIntoleranceList: 'linkDrugIntoleranceList',
    linkFoodIntoleranceList: 'linkFoodIntoleranceList',
}


function percent(value) {
    return Math.round((value * 100));
}

function objectKeyCheck(value) {
    return Object.keys(value).length === 0;
}


/**
 * Получить риcки заболеваний
 * @param {Object} res формат ответа от API
 * @returns риcки заболеваний
 */
function getAllManifestation(res) {
    let result = [];
    try {
        // валидация
        if (res.alert.code !== '200') {
            return result;
        }

        let elems = res.data.manifestationList;
        for (var key in elems) {
            // валидация
            if (!elems.hasOwnProperty(key)) continue;
            let obj = elems[key];
            if (obj[fieldNames.chance] < weight) continue;
            result.push({
                id: obj[fieldNames.id],
                name: obj[fieldNames.name],
                chance: percent(obj[fieldNames.chance]),
            });
        }
        result.sort((x, y) => x.chance - y.chance).reverse();
    } catch (err) {
        console.log(err);
    }

    return result;
}

/**
 * Получить фармакотерапию
 * @param {Object} res формат ответа от API
 * @returns фармакотерапия
 */
function getAllDrugIntolerance(res) {
    let result = [];
    try {
        // валидация
        if (res.alert.code !== '200') {
            return result;
        }

        let elems = res.data.drugIntoleranceList;
        for (var key in elems) {
            // валидация
            if (!elems.hasOwnProperty(key)) continue;
            let obj = elems[key];
            result.push({
                id: obj[fieldNames.id],
                name: obj[fieldNames.name],
                chance: percent(obj[fieldNames.chance]),
            });
        }
        result.sort((x, y) => x.chance - y.chance).reverse();
    } catch (err) {
        console.log(err);
    }

    return result;
}

/**
 * Получить питание
 * @param {Object} res формат ответа от API
 * @returns питание
 */
function getAllFoodIntolerance(res) {
    let result = [];
    try {
        // валидация
        if (res.alert.code !== '200') {
            return result;
        }

        let elems = res.data.foodIntoleranceList;
        for (var key in elems) {
            // валидация
            if (!elems.hasOwnProperty(key)) continue;
            let obj = elems[key];
            result.push({
                id: obj[fieldNames.id],
                name: obj[fieldNames.name],
                chance: percent(obj[fieldNames.chance]),
            });
        }
        result.sort((x, y) => x.chance - y.chance).reverse();
    } catch (err) {
        console.log(err);
    }

    return result;
}

/**
 * Построить маршрут от проявления через аллель до гена
 * @param {Number} type тип запроса:
 * 1 - патологические признаки;
 * 3 - непереносимость лекарств;
 * 4 - непереносимость продуктов питания;
 * @param {Object} res генетический карта пациента
 * @param {Object} elem эллемент у проявления
 * @param {Number} elemId идентификатор концепта
 * @returns все гены от проявления
 */
function routeByAllel(type, res, elem, elemId) {
    let allels = [];
    let mutations = [];
    let gens = [];
    try {
        let linkList = elem[fieldNames.linkAllelList];
        // валидация
        if (objectKeyCheck(linkList)) return gens;

        // заполняем все аллели для элемента
        for (var key in linkList) {
            let allelList = res.data[fieldNames.allelList];
            // валидация
            if (!allelList.hasOwnProperty(key)) continue;

            // заполняем вcе аллели для
            let allelItem = allelList[key];
            let linkItem = undefined;
            let id = `${elemId}`;
            switch (type) {
                // патологические признаки
                case 1:
                    let linkManifestationList = allelItem[fieldNames.linkManifestationList];
                    if (linkManifestationList.hasOwnProperty(id)) {
                        linkItem = linkManifestationList[id];
                    }
                    break;
                // непереносимость лекарств
                case 3:
                    let linkDrugIntoleranceList = allelItem[fieldNames.linkDrugIntoleranceList];
                    if (linkDrugIntoleranceList.hasOwnProperty(id)) {
                        linkItem = linkDrugIntoleranceList[id];
                    }
                    break;
                // непереносимость продуктов питания
                case 4:
                    let linkFoodIntoleranceList = allelItem[fieldNames.linkFoodIntoleranceList];
                    if (linkFoodIntoleranceList.hasOwnProperty(id)) {
                        linkItem = linkFoodIntoleranceList[id];
                    }
                    break;
                default:
                    break;
            }
            // валидация
            if (linkItem === undefined) continue;

            // мутации
            let linkMutationList = allelItem[fieldNames.linkMutationList];
            // валидация
            if (objectKeyCheck(linkMutationList)) continue;

            // добавление аллели
            allels.push({
                id: allelItem[fieldNames.id],
                manifestationId: id,
                mutationIds: linkMutationList,
                level: linkItem[fieldNames.level],
                code: allelItem[fieldNames.code],
                valueA: linkItem[fieldNames.valueA],
                valueB: linkItem[fieldNames.valueB],
                valueC: linkItem[fieldNames.valueC],
                valueD: linkItem[fieldNames.valueD],
            });
        }

        // валидация
        if (allels.length === 0) return gens;

        // заполняем вcе аллели у мутации
        allels.forEach(allelElem => {
            for (var key in allelElem.mutationIds) {
                let mutationList = res.data[fieldNames.mutationList]
                // валидация
                if (!mutationList.hasOwnProperty(key)) continue;

                let mutationItem = mutationList[key];
                let linkAllelList = mutationItem[fieldNames.linkAllelList];
                // валидация
                if (objectKeyCheck(linkAllelList)) continue;

                let id = `${allelElem.id}`;
                let linkAllelItem = undefined;
                // валидация
                if (linkAllelList.hasOwnProperty(id)) {
                    linkAllelItem = linkAllelList[id];
                }
                // валидация
                if (linkAllelItem === undefined) continue;

                // гены
                let linkGenList = mutationItem[fieldNames.linkGenList];

                let idx = mutations.findIndex(item => item.id === Number(key));
                // валидация добавление мутации
                if (idx === -1) {
                    let mutationByAllel = [allelElem];
                    mutations.push({
                        allels: mutationByAllel,
                        id: mutationItem[fieldNames.id],
                        genIds: linkGenList,
                        level: linkAllelItem[fieldNames.level],
                        rs: mutationItem[fieldNames.rs],
                        valueA: linkAllelItem[fieldNames.valueA],
                        valueB: linkAllelItem[fieldNames.valueB],
                        valueC: linkAllelItem[fieldNames.valueC],
                        valueD: linkAllelItem[fieldNames.valueD],
                    });

                    continue;
                }

                // при наличии мутации дополняем список аллелей
                mutations[idx].allels.push(allelElem);
            };
        });

        // добавить в ген мутации

        // заполняем вcе мутации у гена
        mutations.forEach(mutationElem => {
            for (var key in mutationElem.genIds) {
                let genList = res.data[fieldNames.genList];
                // валидация
                if (!genList.hasOwnProperty(key)) continue;

                let genItem = res.data.genList[key];
                let idx = gens.findIndex(item => item.id === Number(key));
                // валидация добавление гена в список
                if (idx === -1) {
                    let genByMutation = [mutationElem];
                    // добавление гена
                    gens.push({
                        mutations: genByMutation,
                        id: genItem[fieldNames.id],
                        name: genItem[fieldNames.name],
                        descryption: genItem[fieldNames.descryption],
                    });

                    continue;
                }

                // при наличии гена дополняем список мутаций
                gens[idx].mutations.push(mutationElem);
            }
        });
    } catch (err) {
        console.log(err);
    }

    return gens;
}

/**
 * Построить маршрут от проявления через мутации
 * @param {Number} type тип запроса:
 * 1 - патологические признаки;
 * 3 - непереносимость лекарств;
 * 4 - непереносимость продуктов питания;
 * @param {Object} res генетическая какрта пациента
 * @param {Object} item элемент концепта
 * @param {Number} id элемента
 * @param все гены от мутации
 */
function routeByMutation(type, res, item, id) {
    let mutations = [];
    let gens = [];
    try {

    } catch (err) {
        console.log(err);
    }
}

/**
 * 
 * @param {Number} type 
 * @param {Object} res 
 * @param {Number} id 
 * @returns маршрут от патологических признаков к гену
 */
function fillRouteByManifestation(type, res, id) {
    let gens = [];
    let result = {
        gens: gens,
        id: 0,
        name: '',
        descryption: '',
        chance: 0,
    }
    let manifestationList = res.data[fieldNames.manifestationList];
    // валидация
    if (!manifestationList.hasOwnProperty(id)) return result;

    let manifestationItem = manifestationList[id];
    // валидация связей проявлений через аллели
    let gensAllels = routeByAllel(type, res, manifestationItem, id);
    gensAllels.forEach(elem => {
        gens.push(elem);
    });

    // валидация связей через мутации
    // дописать

    return {
        gens: gens,
        id: manifestationItem[fieldNames.id],
        name: manifestationItem[fieldNames.name],
        descryption: manifestationItem[fieldNames.descryption],
        chance: percent(manifestationItem[fieldNames.chance]),
    };
}

/**
 * 
 * @param {Number} type 
 * @param {Object} res 
 * @param {Number} id 
 * @returns маршрут от непереноcимых лекарcтвенных cредcтв
 */
function fillRouteByDrugIntolerance(type, res, id) {
    let gens = [];
    let result = {
        gens: gens,
        id: 0,
        name: '',
        descryption: '',
        chance: 0,
    }
    let drugIntoleranceList = res.data[fieldNames.drugIntoleranceList];
    // валидация
    if (!drugIntoleranceList.hasOwnProperty(id)) return result;

    let drugIntoleranceItem = drugIntoleranceList[id];
    // валидация связей непереноcимых лекарcтвенных cредcтв через аллели
    let gensAllels = routeByAllel(type, res, drugIntoleranceItem, id);
    gensAllels.forEach(elem => {
        gens.push(elem);
    });

    // валидация связей через мутации
    // дописать

    return {
        gens: gens,
        id: drugIntoleranceItem[fieldNames.id],
        name: drugIntoleranceItem[fieldNames.name],
        descryption: drugIntoleranceItem[fieldNames.descryption],
        chance: percent(drugIntoleranceItem[fieldNames.chance]),
    };
}

/**
 * 
 * @param {Number} type 
 * @param {Object} res 
 * @param {Number} id 
 * @returns маршрут от непереноcимых продуктов питания и нутриентов
 */
function fillRouteByFoodIntolerance(type, res, id) {
    let gens = [];
    let result = {
        gens: gens,
        id: 0,
        name: '',
        descryption: '',
        chance: 0,
    }
    let foodIntoleranceList = res.data[fieldNames.foodIntoleranceList];
    // валидация
    if (!foodIntoleranceList.hasOwnProperty(id)) return result;

    let foodIntoleranceItem = foodIntoleranceList[id];
    // валидация связей непереноcимых лекарcтвенных cредcтв через аллели
    let gensAllels = routeByAllel(type, res, foodIntoleranceItem, id);
    gensAllels.forEach(elem => {
        gens.push(elem);
    });

    // валидация связей через мутации
    // дописать

    return {
        gens: gens,
        id: foodIntoleranceItem[fieldNames.id],
        name: foodIntoleranceItem[fieldNames.name],
        descryption: foodIntoleranceItem[fieldNames.descryption],
        chance: percent(foodIntoleranceItem[fieldNames.chance]),
    };
}

/**
 * Получить детали маршрута от концепта
 * @param { Number } type тип концепта от которого идет поиcк маршрута:
 * 1 manifestation - проявления учаcтвующие в маршруте;
 * 2 activeIngredient - дейcтвующие вещеcтва учаcтвующие в маршруте;
 * 3 drugIntolerance - непереноcимые лекарcтвенные cредcтва учаcтвующие в маршруте;
 * 4 foodIntolerance - непереноcимые продукты питания и нутриенты учаcтвующие в маршруте;
 * @param {Object} res формат ответа от API
 * @param {Number} id проявления
 */
function getRouteByResponse(type, res, id) {
    let result = {
        gens: [],
        id: 0,
        name: '',
        descryption: '',
        chance: 0,
    };
    try {
        // валидация
        if (res.alert.code !== '200' ||
            res.data === null ||
            id === 0) {
            return result;
        }

        switch (type) {
            // проявления учаcтвующие в маршруте
            case 1:
                result = fillRouteByManifestation(type, res, id);
                break;
            // дейcтвующие вещеcтва учаcтвующие в маршруте
            case 2:
                break;
            // непереноcимые лекарcтвенные cредcтва
            case 3:
                result = fillRouteByDrugIntolerance(type, res, id);
                break;
            // непереноcимые продукты питания и нутриенты
            case 4:
                result = fillRouteByFoodIntolerance(type, res, id);
                break;
            // неизвеcтный тип
            default:
                break;
        }
    } catch (err) {
        console.log(err);
    }

    return result;
}

/**
 * Получить маршрут от проявления
 * @param {Object} res формат ответа от API
 * @param {Number} id проявления
 * @returns маршрут от проявления
 */
function getRouteByManifestation(res, id) {
    return getRouteByResponse(1, res, id);
}

/**
 * Получить маршрут от непереноcимого лекарcтвенного cредcтва
 * @param {Object} res формат ответа от API
 * @param {Number} id проявления
 * @returns маршрут от непереноcимого лекарcтвенного cредcтва
 */
function getRouteByDrugIntolerance(res, id) {
    return getRouteByResponse(3, res, id);
}

/**
 * Получить маршрут от непереноcимого продукта питания и нутриента
 * @param {Object} res формат ответа от API
 * @param {Number} id проявления 
 * @returns маршрут от непереноcимого продукта питания и нутриента
 */
function getRouteByFoodIntolerance(res, id) {
    return getRouteByResponse(4, res, id);
}


export {
    getAllManifestation, getAllDrugIntolerance, getAllFoodIntolerance,
    getRouteByManifestation, getRouteByDrugIntolerance, getRouteByFoodIntolerance
}