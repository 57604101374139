import { urnMain } from '../helper/UrlRouter';
import { alertWarn } from '../helper/ToastAlert';


const msgWarn = "Неверно указан логин или пароль!";


/**
 * Бизнес логика входа в систему
 * @param {Object} values параметры
 * @returns домашняя страница
 */
async function handleFormSubmit(values) {
    if (values.email === 'admin' && values.pass === 'admin') {
        window.location.href = `${urnMain}`;

        return;
    }

    alertWarn(msgWarn);
}


export {
    handleFormSubmit
}