const urnSession = `/session`;
const urnManifestation = `/manifestation`;
const urnDrugIntolerance = `/drugIntolerance`;
const urnFoodIntolerance = `/foodIntolerance`;

// Аутентификация пользователя
const urnHome = `/`;
const urnSignIn = `${urnSession}/signIn`;
const urnSignOut = `${urnSession}/signOut`;

// Домашняя страница
const urnMain = `/main`;
// Информация о рисках заболеваний
const urnManifestationInfo = `${urnManifestation}/info/`;
// Информация о фармакологии
const urnDrugIntoleranceInfo = `${urnDrugIntolerance}/info/`;
// Информация о продуктах питания
const urnFoodIntoleranceInfo = `${urnFoodIntolerance}/info/`;


export {
    urnHome, urnSignIn, urnSignOut, urnMain, urnManifestationInfo, urnDrugIntoleranceInfo, urnFoodIntoleranceInfo
}