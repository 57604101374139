import React from 'react';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { List } from '@mui/material';
import { urnManifestationInfo } from '../common/helper/UrlRouter';

import { ManifestationItemLst } from '../components/Lists';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


/**
 * Виджет списка проявлений
 * @param { Object } props параметры
 * @returns виджет
 */
export default function ManifestationList(props) {
    const navigate = useNavigate();


    const handleClick = (id) => {
        navigate(`${urnManifestationInfo}${id}`);
    }

    const Icon = () => {
        return(
            <InfoOutlinedIcon 
                sx={{width: '34px', height:'34px'}} 
            />
        )
    }


    return (
        <List
            sx={{
                width: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
            }}>
                {props.elems.map((elem) => (                    
                    <ManifestationItemLst 
                        key={uuidv4()} id={elem.id} name={elem.name} chance={elem.chance} 
                        icon={<Icon />}
                        handleClick={handleClick}
                    />
                ))}
        </List>
    )
}
