import React, { Component } from 'react';


/**
 * Компонент картинка приветствия
 */
class WelcomeImg extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <img src={this.props.src} width={this.props.width} alt={this.props.alt} />
        )
    }
}


export {
    WelcomeImg
}