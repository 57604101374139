function alert204(msg) {
    return {
        code: '204',
        actionCode: 0,
        title: 'No Content',
        message: msg,
        level: '',
        sticky: true
    };
}

function alert500(msg){
    return {
        code: '500',
        actionCode: 0,
        title: 'Internal Server Error',
        message: msg,
        level: '',
        sticky: true
    };
} 


export {
    alert204, alert500
}