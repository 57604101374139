import React from 'react';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { List } from '@mui/material';

import { urnFoodIntoleranceInfo } from '../common/helper/UrlRouter';

import { FoodIntoleranceItemLst } from '../components/Lists';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


/**
 * Виджет списка проявлений
 * @returns виджет
 */
export default function FoodIntoleranceList(props) {
    const navigate = useNavigate();


    const handleClick = (id) => {
        navigate(`${urnFoodIntoleranceInfo}${id}`);
    }

    const Icon = () => {
        return(
            <InfoOutlinedIcon 
                sx={{width: '34px', height:'34px'}} 
            />
        )
    }

    return (
        <List
            sx={{
                width: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
            }}>
                {props.elems.map((elem) => (
                    <FoodIntoleranceItemLst 
                        key={uuidv4()} id={elem.id} name={elem.name} chance={elem.chance}
                        icon={<Icon />}
                        handleClick={handleClick}
                    />
                ))}
        </List>
    )
}
