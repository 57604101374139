import React from 'react';
import { useDispatch } from 'react-redux';
import { clearGeneticMap } from '../shared/reducers/GeneticMapSlice';
import { resetFileInfo } from '../shared/reducers/FileInfoSlice';
import { AlertToast } from '../components/Alerts';
import { getFileFormat } from '../common/widgets/PInterpreter';

import AppBarMenu from '../widgets/AppBar';
import PanelPreload from '../widgets/PanelPreload';
import PanelAnalysis from '../widgets/PanelAnalysis';
import PanelStudies from '../widgets/PanelStudies';


const model = {
    appBarMenu: {
        headerValue: 'Исследование генома',
    },
}


/**
 * Страница интерпритации генома человека
 * @returns страница
 */
export default function PInterpreter() {
    const dispatch = useDispatch();


    const handleClearChange = () => {
        dispatch(resetFileInfo());
        dispatch(clearGeneticMap());
    };


    return (
        <>
            <AppBarMenu headerValue={model.appBarMenu.headerValue} />
            <PanelPreload
                handleClearChange={handleClearChange}
            />
            <PanelAnalysis
                handleClearChange={handleClearChange} getFileFormat={getFileFormat}
            />
            <PanelStudies />
            <AlertToast />
        </>
    )
}
