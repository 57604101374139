import { createSlice } from '@reduxjs/toolkit';
import { alert204 } from '../../common/helper/Alert';
import moq from '../../common/moq/E20B1112271';


const initialState = {
    //data: moq()
    data: {
        "alert": alert204(),
        "data": null,
    }
};


export const geneticMapSlice = createSlice({
    name: 'geneticMap',
    initialState,
    reducers: {
        setGeneticMap: (state, action) => {
            state.data = action.payload;
        },
        clearGeneticMap: (state) => {
            state.data = null;
        }
    },
})


export const { setGeneticMap, clearGeneticMap } = geneticMapSlice.actions

export default geneticMapSlice.reducer
