import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGenomeSciencePanel } from '../shared/reducers/GenomeSciencePanelSlice';
import { setGeneticMap, clearGeneticMap } from '../shared/reducers/GeneticMapSlice';
import {
    Grid, Accordion, AccordionDetails
} from '@mui/material';
import { apiFileToAnalysisAsync } from '../common/api/ConverterController';
import {
    apiRouteAllAsync,
    requestApiRouteAll,
    apiRouteAllByFileAsync,
} from '../common/api/InterpreterController';

import { ImgSummaryAcn } from '../components/Accordions';
import { ProgressCard, ProgressErrorCard } from '../components/Cards';
import { StartIconBtn } from '../components/Buttons';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


const model = {
    id: "2a",
    svg: <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9 0.999192C14.73 0.999192 14.58 1.08919 14.49 1.22919L14.42 1.37919L9.24 13.0292C9.08 13.3192 8.98 13.6392 8.98 13.9892C8.98 15.0992 9.88 15.9992 10.99 15.9992C11.95 15.9992 12.76 15.3192 12.95 14.4092L12.96 14.3792L15.4 1.49919C15.4 1.21919 15.18 0.999192 14.9 0.999192ZM0 4.99919L2 6.99919C4.88 4.11919 8.79 2.91919 12.53 3.37919L13.72 0.699192C8.89 -0.160808 3.74 1.26919 0 4.99919ZM20 6.99919L22 4.99919C20.4188 3.41646 18.511 2.19808 16.41 1.42919L15.88 4.24919C17.38 4.86919 18.78 5.77919 20 6.99919ZM16 10.9992L18 8.99919C17.2 8.19919 16.3 7.57919 15.34 7.10919L14.79 10.0292C15.21 10.2992 15.62 10.6192 16 10.9992ZM4 8.99919L6 10.9992C7.09101 9.91329 8.50538 9.21137 10.03 8.99919L11.31 6.11919C8.68 6.03919 6.01 6.98919 4 8.99919Z" fill="#8185B6" /></svg>,
    value: "Обработка загруженного файла",
    progressCard: {
        value: "Пожалуйста ожидайте завершение, Ваш файл сейчас в обработке!",
    },
    button: {
        value: "Начать обработку",
    },
    error: {
        value: "Необходимо загрузить файл для анализа ...",
    }
};


/**
 * Виджет панели запуска и мониторинга статуса выполнения исследования
 * @returns виджет
 */
export default function PanelAnalysis(props) {
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(false);
    const genomeSciencePanel = useSelector((state) => state.genomeSciencePanel.value);
    const fileInfo = useSelector((state) => state.fileInfo.data);


    const handleClick = async () => {
        if (!fileInfo.fl) {
            return;
        }

        dispatch(setGeneticMap(null));
        setProgress(true);

        let format = props.getFileFormat(fileInfo);
        if (format === 'gvcf') {
            let res1 = await apiFileToAnalysisAsync(fileInfo.formData);
            // Валидация
            if (res1?.alert?.code !== "200") {
                props.handleClearChange();
                setProgress(false);
                dispatch(setGenomeSciencePanel('panel1'));

                return;
            }

            let req = requestApiRouteAll('', res1.data);
            let res2 = await apiRouteAllAsync(req);
            // Валидация
            if (res2?.alert?.code !== "200") {
                props.handleClearChange();
                setProgress(false);
                dispatch(setGenomeSciencePanel('panel1'));

                return;
            }

            dispatch(setGeneticMap(res2));
        } else if (format === 'vcf' || format === 'csv') {
            let res = await apiRouteAllByFileAsync(fileInfo.formData);
            // Валидация
            if (res?.alert?.code !== "200") {
                props.handleClearChange();
                setProgress(false);
                dispatch(setGenomeSciencePanel('panel1'));

                return;
            }

            dispatch(setGeneticMap(res));
        } else {
            // not support
            dispatch(clearGeneticMap());
            setProgress(false);
            dispatch(setGenomeSciencePanel('panel1'));

            return;
        }

        setProgress(false);
        dispatch(setGenomeSciencePanel('panel3'));
    };

    const handleChange = (panel) => (event, newExpanded) => {
        dispatch(setGenomeSciencePanel(newExpanded ? panel : false));
    };

    const InProcessing = () => {
        return (
            <Grid
                container>
                <Grid
                    item xs={1} sm={1} md={2} lg={2} xl={2}
                />
                <Grid
                    item xs={10} sm={10} md={8} lg={8} xl={8}
                >
                    <ProgressCard
                        value={model.progressCard.value}
                    />
                </Grid>
                <Grid
                    item xs={1} sm={1} md={2} lg={2} xl={2}
                />
            </Grid>
        );
    }

    const OnProcessing = () => {
        if (fileInfo.name === '' && fileInfo.size === 0) {
            return (
                <Grid
                    container>
                    <Grid
                        item xs={1} sm={1} md={2} lg={2} xl={2} />
                    <Grid
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        item xs={10} sm={10} md={8} lg={8} xl={8}
                    >
                        <ProgressErrorCard 
                            value={model.error.value} 
                        />
                    </Grid>
                    <Grid
                        item xs={1} sm={1} md={2} lg={2} xl={2} />
                </Grid>
            );
        } else {
            return (
                <Grid
                    container>
                    <Grid
                        item xs={1} sm={3} md={4} lg={5} xl={5}
                    />
                    <Grid
                        item xs={10} sm={6} md={4} lg={2} xl={2}
                    >
                        <StartIconBtn
                            startIcon={<PlayCircleOutlineIcon />} value={model.button.value}
                            handleClick={handleClick}
                        />
                    </Grid>
                    <Grid
                        item xs={1} sm={3} md={4} lg={5} xl={5}
                    />
                </Grid>
            );
        }
    }

    const IsProgress = () => {
        if (progress) {
            return (<InProcessing />)
        } else {
            return (<OnProcessing />)
        }
    }


    return (
        <Accordion expanded={genomeSciencePanel === 'panel2'} onChange={handleChange('panel2')}>
            <ImgSummaryAcn id={model.id} svg={model.svg} value={model.value} />
            <AccordionDetails>
                <IsProgress />
            </AccordionDetails>
        </Accordion>
    )
}
