import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from "../src/app/router";
import { BrowserRouter } from 'react-router-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';

import './shared/index.css';


/**
 * Стартовая страница ui интерфейса
 */
const ui = ReactDOM.createRoot(document.getElementById('ui'));
ui.render(
    <BrowserRouter>
        <Provider store={store}>
            <Router />
        </Provider>
    </BrowserRouter>
);