import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: 'subPanel0',
};

export const genomeStudiesPanel = createSlice({
    name: 'genomeStudiesPanel',
    initialState,
    reducers: {
        setGenomeStudiesPanel: (state, action) => {
            state.value = action.payload;
        }
    }
});


export const { setGenomeStudiesPanel } = genomeStudiesPanel.actions;
export default genomeStudiesPanel.reducer;